.brands {
  display: none;
  margin-top: 64px;
  margin-bottom: 64px;

  @include from('lg') {
    display: block;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      max-width: 150px;
    }
  }
}
