.woocommerce div.product {
  .product_title {
    font-size: 42px;
    font-weight: 900;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      width: 128px;
      height: 2px;
      margin: 20px 0;
      background: $black;
    }
  }

  .onsale {
    position: absolute;
    min-height: initial;
    min-width: initial;
    line-height: initial;
    top: 8px;
    left: 8px;
    padding: 6px 10px;
    background: $primary;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 4px;
  }

  .woocommerce-product-details__short-description {
    margin: 16px 0;
    font-size: 16px;

    p {
      & + p {
        margin-top: 16px;
      }
    }
  }

  p.price,
  span.price {
    color: $black;
    font-size: 32px;

    del {
      font-size: 24px;
    }

    ins {
      text-decoration: none;
    }
  }

  .stock {
    margin-bottom: 16px;
  }

  form.cart {
    position: relative;

    div.quantity {
      margin-bottom: 16px;

      @include from('lg') {
        margin-bottom: 0;
      }

      & > span {
        display: none;
      }
    }

    .single_add_to_cart_button {
      height: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      background: #16884F;
      border-color: #81D373;
      position: relative;
      padding-left: 35px;

      &:hover {
        background: darken(#16884F, 10%);
      }

      &::before {
        display: inline-block;
        content: url("../images/shopping-cart.svg");
        position: absolute;
        left: 10px;
        top: 13px;
      }
    }
  }

  .product_meta {
    display: flex;
    flex-direction: column;
  }

  div.images {
    margin-bottom: 64px;

    .flex-control-thumbs {
      margin: 0 -8px;

      li {
        padding: 8px;

        img {
          border: 1px solid #c4c4c4;
        }
      }
    }
  }

  .woocommerce-tabs {
    padding-top: 64px;
    border-top: 1px solid $primary;

    @include from('lg') {
      display: flex;
    }

    ul.tabs {
      overflow: initial;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 224px;
      max-width: 224px;
      margin: 0;
      padding: 0;

      &::before,
      &::after {
        display: none;
      }

      li {
        display: block;
        width: 100%;
        margin: 0;
        background: $white;
        border: none;
        border-radius: 0;

        &::before,
        &::after {
          display: none;
        }

        & + li {
          border-top: 1px solid $primary;
        }

        a {
          color: $primary;
          font-weight: 400;
        }
      }
    }

    .panel {
      font-size: 16px;

      @include from('lg') {
        padding-left: 32px;
      }

      h2 {
        display: none;
      }

      P {
        & + p {
          margin-top: 16px;
        }
      }
    }
  }

  .related {
    clear: both;
    margin-top: 64px;

    & > h2 {
      margin-bottom: 32px;
      padding-bottom: 16px;
      font-size: 32px;
      font-weight: 900;
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
    }
  }
}

.up-sells h2 {
  display: inline-block;
}
