.home-ctas-wrapper {
  margin: 32px 0;

  .row {
    margin-left: -32px;
    margin-right: -32px;

    @include until('lg') {
      .col {
        & + .col {
          margin-top: 16px;
        }
      }
    }
  }

  .home-cta-link {
    display: block;
    height: 100%;
    padding: 16px;
    background: #DCDCDC;
    color: $black;
    transition: all 0.2s ease;

    &:hover {
      background: $primary;
      color: $white;
      text-decoration: none;

      .home-cta-card {
        img {
          transform: scale(0.8);
        }
      }
    }

    .home-cta-card {
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 16px;
      border: 1px solid $white;

      @include until('md') {
        flex-direction: column;
      }

      .home-cta-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        word-break: break-word;

        @media screen and (min-width: 1600px) {
          width: 60%;
        }

        h3 {
          font-size: 26px;
          font-weight: 900;
          text-transform: uppercase;

          @include from('lg') {
            font-size: 36px;
          }
        }
      }

      .home-cta-img {
        display: none;
        position: relative;
        padding-left: 32px;
        height: 225px;

        @media screen and (min-width: 1600px) {
          display: flex;
          justify-content: center;
        }

        img {
          max-height: 100%;
          object-fit: contain;
          transition: transform 0.2s ease;
        }
      }
    }
  }
}
