.home {
  .frontpage-slider {
    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 466px;
      background: #ccc;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      @include from('lg') {
        height: 640px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba($black, 0.5), rgba($black, 0) 60%);
        z-index: 10;
      }

      &.slide-dark {
        .slide-content {
          color: #333;
        }
      }

      &.swiper-slide-active {
        .slide-content {
          h2,
          .slide-subtitle,
          a {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .slide-content {
        position: relative;
        color: $white;
        z-index: 100;

        h2,
        .slide-subtitle,
        a {
          opacity: 0;
          transform: translateY(60px);
          transition: all 0.8s ease;
        }

        .slide-subtitle {
          transition-delay: 0.5s;
        }

        a {
          transition-delay: 0.8s;
        }

        h2 {
          max-width: 600px;
          margin-bottom: 10px;
          font-size: 56px;
          font-weight: 900;
          text-transform: uppercase;

          @include until('xl') {
            font-size: 32px;
          }
        }

        .slide-subtitle {
          margin-bottom: 16px;
        }
      }
    }

    .swiper-button-next, .swiper-button-prev {
      position: absolute;
      display: none;
      top: 50%;
      width: 48px;
      height: 84px;
      background: $white;
      z-index: $zIndex-1;

      @include from('xl') {
        display: flex;
      }
    }

    .swiper-button-next {
      right: 0;
      border-radius: 8px 0px 0px 8px;
    }

    .swiper-button-prev {
      left: 0;
      border-radius: 0px 8px 8px 0px;
    }
  }
}
