// Name:            Grid
// Description:     Styles for the grid system
//
// Namespace:       `grid`
//
// Modifiers:       ``
//
// States:          ``
//
// ========================================================================


// Variables
// ========================================================================

$gutter-width        : 40px;
$outer-margin        : 32px;
$gutter-compensation : 16px;
$half-gutter-width   : calc(#{$gutter-width} * 0.5);

$grid-size-10        : percentage(1/12);
$grid-size-20        : percentage(2/12);
$grid-size-25        : percentage(3/12);
$grid-size-33        : percentage(4/12);
$grid-size-40        : percentage(5/12);
$grid-size-50        : percentage(6/12);
$grid-size-60        : percentage(7/12);
$grid-size-66        : percentage(8/12);
$grid-size-75        : percentage(9/12);
$grid-size-80        : percentage(10/12);
$grid-size-90        : percentage(11/12);
$grid-size-100       : 100%;

$columns: (
  "1": $grid-size-10,
  "2": $grid-size-20,
  "3": $grid-size-25,
  "4": $grid-size-33,
  "5": $grid-size-40,
  "6": $grid-size-50,
  "7": $grid-size-60,
  "8": $grid-size-66,
  "9": $grid-size-75,
  "10": $grid-size-80,
  "11": $grid-size-90,
  "12": $grid-size-100
);

// Component: Grid
// ========================================================================

.container,
.container-fluid {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 16px;
}

.container {
  max-width: 1329px;
}

.container-fluid {
  max-width: 100%;
  padding: 0 $outer-margin;
}

.row {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-right: -$gutter-compensation;
  margin-left: -$gutter-compensation;

  &.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > .col {
      padding: 0;
    }
  }
}

.col {
  position: relative;
  flex: 1 0 auto;
  padding-right: $gutter-compensation;
  padding-left: $gutter-compensation;
  max-width: 100%;

  @each $breakpoint, $breakpoint-size in $sizes {
    @if $breakpoint == 'sm' {
      @each $name, $size in $columns {
        &.col-#{$breakpoint}-#{$name} {
          flex-basis: $size;
          max-width: $size;
        }

        &.offset-#{$breakpoint}-#{$name} {
          margin-left: $size;
        }
      }
    } @else {
      @include from($breakpoint) {
        @each $name, $size in $columns {
          &.col-#{$breakpoint}-#{$name} {
            flex-basis: $size;
            max-width: $size;
          }

          &.offset-#{$breakpoint}-#{$name} {
            margin-left: $size;
          }
        }
      }
    }
  }
}
