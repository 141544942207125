// Name            Typography
// Description     Typographic styles
//
// Namespace       `type`
//
// ========================================================================


// Variables
// ========================================================================

$font-1      : 48px;
$font-2      : 40px;
$font-3      : 36px;
$font-4      : 32px;
$font-5      : 24px;
$font-6      : 18px;
$font-7      : 16px;
$font-8      : 14px;
$font-9      : 12px;
$font-10     : 11px;

$font-tiny   : $font-9;
$font-small  : $font-8;
$font-normal : $font-7;
$font-medium : $font-6;
$font-mlarge : $font-5;
$font-large  : $font-4;
$font-huge   : $font-2;

// Component Typography
// ========================================================================

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: $font-1;
  line-height: 1.2;
}

h2 {
  font-size: $font-2;
  line-height: 1.25;
}

h3 {
  font-size: $font-3;
  line-height: 1.3;
}

h4 {
  font-size: $font-4;
  line-height: 1.35;
}

h5 {
  font-size: $font-5;
  line-height: 1.5;
}

h6 {
  font-size: $font-6;
  line-height: 1.4;
}

p {
  margin-top: 0;

  & + p {
    margin-top: 16px;
  }
}

b,
strong {
  font-weight: 700;
}
