footer.content-info {
  margin-top: 64px;

  .footer-content {
    position: relative;
    padding: 40px 0;
    background-image: url('../images/footer-bg.jpg');
    background-position: center center;
    background-size: cover;
    color: $white;

    @include from('lg') {
      padding: 80px 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.75);
      z-index: $zIndex-1;
    }

    .container {
      position: relative;
      z-index: $zIndex-2;
    }

    .col {
      @include until('md') {
        & + .col {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }
    }

    .footer-logos {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @include from('md') {
        flex-direction: column;
        justify-content: initial;

        img {
          & + img {
            margin-top: 16px;
            margin-left: 0;
          }
        }
      }

      @include until('md') {
        img {
          width: 100px;
          height: auto;

          & + img {
            margin-left: 16px;
          }
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    h4 {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    p,
    a {
      font-size: 14px;
    }

    .column-content {
      padding-bottom: 16px;
    }

    .social-media {
      display: flex;
      margin-top: 20px;

      a {
        display: block;
        position: relative;
        width: 30px;
        height: 30px;

        &.facebook::before {
          content: url("../images/facebook.svg");
          position: absolute;
        }

        &.instagram::before {
          content: url("../images/instagram.svg");
          position: absolute;
        }

        @include from('lg') {
          & + a {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

.colophon {
  .col {
    text-align: center;

    @include from('lg') {
      text-align: left;
    }
  }

  .colophon-copy {
    display: block;

    @include from('lg') {
      text-align: right;
    }
  }
}
