@import "~normalize.css/normalize.css";

html,body,p,figure,textarea,pre,h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

body.contact {
  .gform_wrapper {
    margin: 0;

    span.gform_description {
      font-size: 20px;
    }

    .top_label div.ginput_container {
      margin-top: 0;
    }

    ul li.gfield {
      margin: 0;
      padding-right: 0 !important;

      & + li.gfield {
        margin-top: 0;
      }
    }

    label.gfield_label {
      font-size: 16px;
      font-weight: 400;
    }

    input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
    textarea.large {
      margin-top: 12px;
      padding: 8px 10px;
      background: $white;
      border: 1px solid $primary;
      border-radius: 4px;
    }
  }

  .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    max-width: 100% !important;
  }
}
