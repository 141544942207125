.tabbed-content {
  margin-top: 64px;

  h2 {
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .tabs-items {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        & + li {
          margin-top: 8px;
        }

        button {
          &.active {
            background: $primary;
            color: $white;
          }
        }
      }
    }
  }

  .tabs-content {
    .tab {
      display: none;

      &.active {
        display: flex;
      }
    }
  }
}
