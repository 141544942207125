// Name:           Forms
// Description:    Form styles
//
// Namespace:      `form`
//
// Modifiers:      `component-modifier`
//
// States:         `component-state`
//
// ========================================================================

// Variables
// ========================================================================

$form-background-color: $white;
$form-font-size: $font-small;
$form-font-size-sm: $font-small;
$form-font-size-lg: $font-large;
$form-padding-y: 12px;
$form-padding-x: 18px;
$form-radius: 4px; // deprecate

// Component: Forms
// ========================================================================

.form__group {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.form__label {
  display: block;
  padding: 6px 0;
  text-transform: uppercase;
}

.form__input,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  appearance: none;
  display: block;
  position: relative;
  width: 100%;
  padding: $form-padding-y $form-padding-x;
  background: $form-background-color;
  color: $black;
  font-size: $form-font-size;
  line-height: 1.4;
  border: 1px solid $primary;
  border-radius: $form-radius;
  box-shadow: 0px 2px 4px rgba($black, 0.15);
  transition: all .2s ease;

  &:focus {
    background-color: $white;
    transition: all .2s ease;
    outline: none;
  }

  &::placeholder {
    color: $grey;
  }
}

.form__input--hint {
  margin-top: 4px;
  color: $grey-600;
  font-size: $font-small;
}

.form__select {
  padding: $form-padding-y $form-padding-x;
  width: 100%;
  appearance: none;
  border: 1px solid $grey;
  border-radius: $form-radius;
  color: inherit;
  font-size: $form-font-size;
  line-height: 20px;
  outline: none;
  vertical-align: middle;

  & option {
    padding: 2px 4px;
  }

  &:not([multiple]) {
    background: $form-background-color url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23667189' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 7.5px center / 8px 10px;
  }

  &:focus {
    border-color: rgb(var(--primary));
  }

  &.select-sm {
    font-size: $form-font-size-sm;
    height: 24px;
    padding: 1px 20px 1px 6px;
  }


  &.select-lg {
    font-size: $form-font-size-lg;
    height: 40px;
    padding: 9px 24px 9px 8px;
  }
}

.form__checkbox,
.form__radio,
.form__switch {
  position: relative;
  display: inline-block;
  font-size: $font-small;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;

  input {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  .form__icon {
    background: rgba($black, 0.05);
    border: 1px solid #dadada;
    cursor: pointer;
    display: inline-block;
    position: absolute;
  }
}


.form__checkbox,
.form__radio {
  padding: 6px 24px;

  .form__icon {
    height: 16px;
    left: 0;
    top: 8px;
    width: 16px;
  }

  input {
    &:active + .form__icon {
      background: $grey;
    }
  }
}

.form__checkbox {
  .form__icon {
    border-radius: 4px;
  }

  input {
    &:checked + .form__icon {
      &::before {
        background-clip: padding-box;
        border: 2px solid #fff;
        border-left-width: 0;
        border-top-width: 0;
        content: "";
        height: 10px;
        left: 50%;
        margin-left: -3px;
        margin-top: -6px;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 6px;
      }
    }

    &:indeterminate + .form__icon {
      background: rgb(var(--primary));
      border-color: rgb(var(--primary));

      &::before {
        background: $form-background-color;
        content: "";
        height: 2px;
        left: 50%;
        margin-left: -5px;
        margin-top: -1px;
        position: absolute;
        top: 50%;
        width: 10px;
      }
    }
  }
}

.form__radio {
  .form__icon {
    border-radius: 7px;
  }

  input {
    &:checked + .form__icon {
      &::before {
        background: $form-background-color;
        border-radius: $form-radius;
        content: "";
        height: 4px;
        left: 50%;
        margin-left: -2px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 4px;
      }
    }
  }
}

.form__switch {
  padding: 0 0 0 64px;

  .form__icon {
    background: #fafafa;
    background-clip: padding-box;
    width: 54px;
    height: 26px;
    left: 0;
    top: -50%;
    transform: translateY(25%);
    border-radius: 100px;

    &::before {
      background: $secondary;
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      top: 1px;
      left: 1px;
      position: absolute;
      border-radius: 100px;
      transition: left 0.15s ease;
    }
  }

  input {
    &:checked + .form__icon {
      &::before {
        left: 29px;
      }
    }

    &:active + .form__icon {
      &::before {
        background: darken($secondary, 10%);
      }
    }
  }
}

.input__group {
  display: flex;

  .input-group-addon {
    background: $form-background-color;
    border: 1px solid $grey;
    border-radius: $form-radius;
    line-height: 2rem;
    padding: 5px 8px;

    &.addon-sm {
      font-size: $form-font-size-sm;
      padding: 1px 8px;
    }

    &.addon-lg {
      font-size: $form-font-size-lg;
      padding: 9px 8px;
    }
  }

  .input-group-addon,
  .input-group-btn {
    flex: 1 0 auto;
  }

  .form__input,
  .input-group-addon,
  .input-group-btn {

    &:first-child:not(:last-child) {
      @include border-right-radius(0);
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      margin-left: -1px;
    }

    &:last-child:not(:first-child) {
      @include border-left-radius(0);
      margin-left: -1px;
    }

    &:focus {
      z-index: $zIndex-0;
    }
  }

  .input-group-btn {
    position: relative;

    &::before {
      content: url("../images/search.svg");
      position: absolute;
      top: 6px;
      left: 5px;
    }
  }

  &.input-inline {
    display: inline-flex;
  }
}

.form__input,
.form__select {
  &:disabled,
  &.disabled {
    background-color: $grey;
    cursor: not-allowed;
    opacity: .5;
  }
}

input {
  &:disabled,
  &.disabled {
    & + .form__icon {
      background: $grey;
      cursor: not-allowed;
      opacity: .5;
    }
  }
}

.form__switch {
  input {
    &:disabled,
    &.disabled {
      & + .form__icon::before {
        background: $form-background-color;
      }
    }
  }
}

.form__horizontal {
  padding: 16px;

  .form__group {
    display: flex;
  }


  .form__checkbox,
  .form__radio,
  .form__switch {
    margin: 4px 0;
  }
}

textarea {
  &.form__input {
    height: auto;
    padding: 9px;
  }
}

.gform_wrapper {
  margin: 0;

  span.gform_description {
    font-size: 20px;
  }

  ul li.gfield {
    margin: 0;

    & + li.gfield {
      margin-top: 16px;
    }

    &.gfield_error {
      background-color: initial;
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  label.gfield_label {
    font-size: 16px;
    font-weight: 400;
  }

  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
  textarea.large {
    padding: 8px 10px !important;
    background: $white;
    border: 1px solid #dadada;
  }

  form {
    div.validation_error {
      border: none;
    }

    .field_description_below .gfield_description {
      padding-top: 4px;
    }
  }
}
