// Name:            Button
// Description:     Styles for buttons
//
// Namespace:       `btn`
//
// Modifiers:       `btn--sm`
//                  `btn--lg`
//                  `btn--outline`
//                  `btn--clear`
//                  `btn--raised`
//                  `btn--block`
//                  `btn--narrow`
//                  `btn--$color`
//
//
// ========================================================================


// Variables
// ========================================================================
$btn-background: $primary;
$btn-background-hover: $primary;
$btn-color: $primary;

// Component: Component
// ========================================================================

.btn,
.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'],
.woocommerce a.button,
.woocommerce button.button {
  display: inline-block;
  padding: 6px 20px;
  background-color: $white;
  color: $btn-color;
  cursor: pointer;
  font-family: $ff-sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.618;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  opacity: 1;
  border: 1px solid $btn-background;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    outline: 0;
    background-color: $btn-background-hover;
    color: $white;
    text-decoration: none;
    border-color: $btn-background-hover;
    transition: all 0.2s ease;
  }

  &.disabled,
  &[disabled] {
    pointer-events: none;
    cursor: default;
    background-color: $btn-background;
    border-color: $btn-background;
    color: #eee;
    opacity: 0.5;

    &:hover,
    &:focus {
      background-color: $btn-background;
      border-color: $btn-background;
    }
  }

  &.btn-block {
    display: block;
    width: 100%;
  }

  // Set styles for each colors defined in variables.styl
  @each $name, $map in $colors {
    $color: map-get($map, 'color');
    $invert: map-get($map, 'invert');

    &.btn-#{$name} {
      background-color: $color;
      border-color: $color;
      color: $invert;

      &:hover,
      &:focus {
        background: darken($color, 5%);
        border-color: darken($color, 5%);
        color: $invert;
      }

      &:active {
        border-color: transparent;
      }
    }
  }
}

// Button Groups
.btn__group {
  display: inline-flex;
  flex-wrap: wrap;

  & .btn {
    &:first-child:not(:last-child) {
      @include border-right-radius(0);
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      margin-left: -1px;
    }

    &:last-child:not(:first-child) {
      @include border-left-radius(0);
      margin-left: -1px;
    }

    &:hover,
    &:focus {
      z-index: $zIndex-1;
    }
  }

  &.btn__group--block {
    display: flex;

    & .btn {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }
  }
}
