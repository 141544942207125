.card-large {
  position: relative;
  display: flex;
  padding: 48px;
  background-color: #f3f3f3;
  background-image: url('../images/coffee.jpg');
  background-position: center center;
  background-size: cover;
  border: 1px solid #CECECE;
  border-radius: 4px;

  @include until('lg') {
    margin-top: 64px;
  }

  &.full-w-content {
    justify-content: center;

    .card-content {
      @include from('lg') {
        flex-basis: 80%;
        justify-content: center;
      }

      h2, p {
        max-width: none;
      }
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include from('lg') {
      flex-basis: 50%;
    }

    h2,
    p {
      margin-bottom: 24px;

      @include from('lg') {
        max-width: 405px;
      }
    }

    h2 {
      font-size: 32px;
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
    }
  }

  .card-image {
    display: none;
    position: absolute;
    top: -55px;
    right: 12.5%;

    @include from('lg') {
      display: block;
    }
  }
}
