.btn,
.button,
.woocommerce a.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  padding: 10px 20px;
  box-shadow: 0px 2px 4px rgba($black, 0.15);
}
