// Responsive breakpoints

@mixin border-top-radius($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin until($breakpoint) {
  @each $ident, $size in $sizes {
    @if ($breakpoint == $ident) {
      @media screen and (max-width: $size) {
        @content;
      }
    }
  }
}

@mixin from($breakpoint) {
  @each $ident, $size in $sizes {
    @if ($breakpoint == $ident) {
      @media screen and (min-width: $size) {
        @content;
      }
    }
  }
}

@function color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= 180) {
    @return darken($color, 45%);
  } @else {
    @return $white;
  }
}
