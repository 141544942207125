.card-small {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  background-color: #f3f3f3;
  border: 1px solid #CECECE;
  border-radius: 4px;

  @include from('lg') {
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;

    @include from('lg') {
      width: 65%;
      flex-shrink: 0;
      flex-basis: 65%;
      margin-bottom: 0;

      h2,
      p {
        max-width: 405px;
      }
    }

    h2 {
      margin-bottom: 16px !important;
      font-size: 24px;
      text-transform: uppercase;
    }
  }
}
