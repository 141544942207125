// Vars
@import "core/functions";
@import "variables";

@import "reset";

// Core
@import "core/base";
@import "core/grid";
@import "core/typography";
@import "core/utilities/utilities";
@import "core/components/button";
@import "core/components/form";

// Partials
@import "partials/partials";

// Pages
@import "pages/pages";

@import "~swiper/css/swiper.css";

@import "theme/components/button";
@import "theme/components/home-cta";
@import "theme/components/product-slider";
@import "theme/components/brands";
@import "theme/components/card-large";
@import "theme/components/card-small";
@import "theme/components/tabbed-content";

:root {
  --swiper-theme-color: #{$primary};
  --swiper-navigation-size: 24px;
}

html, body {
  &.no-scroll {
    overflow: hidden;
    height: 100vh;
  }
}

body {
  @include until('lg') {
    margin-top: 61px;
    margin-bottom: 54px;
  }
}

.turbolinks-progress-bar {
  height: 3px;
  background-color: $primary;
}

.mobile-menu {
  display: none;

  @include until('lg') {
    display: initial;
  }

  .mobile-toggle {
    width: 20px;
    height: 16px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $primary;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 7px;
      }

      &:nth-child(4) {
        top: 14px;
      }
    }

    &.open span:nth-child(1) {
      top: 7px;
      width: 0%;
      left: 50%;
    }

    &.open span:nth-child(2) {
      transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
      transform: rotate(-45deg);
    }

    &.open span:nth-child(4) {
      top: 7px;
      width: 0%;
      left: 50%;
    }
  }
}

.woocommerce-nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  ul {
    display: flex;
    list-style: none;

    li {
      i {
        margin-right: 16px;
        color: $primary;
      }

      span {
        font-size: 16px;
      }
    }
  }
}

.woocommerce {
  .content {
    @extend .container;
  }

  .woocommerce-result-count {
    display: none;
  }

  .woocommerce-ordering {
    display: none;

    @include from ('lg') {
      display: flex;
    }

    select {
      appearance: none;
      padding: 10px;
      background: #F0EAE5;
      color: $primary;
      border: 1px solid $primary;
      border-radius: 4px;
    }

    &::after {
      content: url("../images/chevron-down.svg");
      display: block;
      background: $white;
      color: $primary;
      height: 40px;
      pointer-events: none;
      position: absolute;
      width: 40px;
      top: 1px;
      right: 1px;
      padding-top: 6px;
      padding-left: 14px;
      border-left: 1px solid $primary;
      border-radius: 0 4px 4px 0;
      font-family: 'Font Awesome 5 Pro', sans-serif;
      line-height: 29px;
    }
  }

  .woocommerce-sidebar-toggle {
    display: none;

    @include until ('lg') {
      display: initial;
      position: fixed;
      bottom: 66px;
      right: 16px;
      z-index: $zIndex-3;
    }
  }

  .woocommerce-sidebar {
    .close-sidebar {
      display: none;
    }

    @include until ('lg') {
      position: fixed;
      overflow: scroll;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 49px);
      padding-top: 16px;
      padding-bottom: 16px;
      background: $white;
      transform: translateY(100%);
      transition: transform 0.2s ease;
      z-index: $zIndex-4;

      &.active {
        transform: translateY(0);
      }

      .close-sidebar {
        display: initial;
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }
  }

  .woof {
    h4 {
      margin-bottom: 12px;
      font-size: 24px;
      font-weight: 900;
      text-transform: uppercase;
    }

    .woof_list {
      label {
        padding-top: 0;
        padding-bottom: 9px;
      }

      li {
        input[type="checkbox"] {
          position: relative;
          appearance: none;
          margin-top: 4px;
          width: 20px;
          height: 20px;
          background: #F0EAE5;
          border: 1px solid $primary;
          border-radius: 4px;
          box-shadow: 0px 2px 4px rgba($black, 0.15);

          &:checked {
            background: $primary;
            &::before {
              content: url("../images/check.svg");
              position: absolute;
              top: 3px;
              left: 3px;
              color: $white;
              font-size: 12px;
              font-weight: 700;
            }
          }
        }
      }
    }

    .woof_childs_list {
      li {
        display: flex;
      }
    }
  }

  nav.woocommerce-pagination ul {
    border: none;

    li {
      overflow: initial;
      border: none;
      margin: 4px;

      a, span {
        display: block;
        padding: 10px 16px;
        border: 1px solid $primary;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba($black, 0.15);

        &.current {
          background: $primary;
          color: $white;
        }
      }
    }
  }
}

.woocommerce-page.woocommerce-cart .woocommerce-cart-form .shop_table .coupon #coupon_code {
  @include until('md') {
    width: 100% !important;

    & + button {
      display: block;
      width: 100%;
    }
  }
}

.woocommerce-page.woocommerce-cart .cart-collaterals .cart_totals {
  @include until('md') {
    width: 100% !important;
    margin: 0 16px;
  }
}

.product-slider .product,
.woocommerce ul.products li.product {
  position: relative;
  padding-bottom: 8px;

  a.woocommerce-loop-product__link {
    display: block;
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .attachment-woocommerce_thumbnail {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 207px;
    object-fit: contain;
  }

  .onsale {
    position: absolute;
    top: 8px;
    right: 8px;
    min-height: initial;
    margin: 0;
    padding: 4px 8px;
    background: $primary;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: initial;
    letter-spacing: 0.5px;
    border-radius: 4px;
  }

  h2.woocommerce-loop-product__title {
    height: 68px;
    margin-top: 8px;
    font-size: 18px;
    font-weight: 400;
    text-overflow: ellipsis;
  }

  span.price {
    display: block;
    margin-top: 8px;
    color: $black;
    font-size: 20px;
    font-weight: 900;

    del {
      color: $grey;
      font-weight: 400;
    }

    ins {
      color: #D40404;
    }
  }

  .button {
    width: 100%;
    margin-top: 8px;
    font-size: 14px;

    &::before {
      content: url("../images/shopping-bag.svg");
      margin-right: 8px;
    }

    &.product_type_variable {
      &::before {
        content: '\f3f0';
      }
    }
  }
}

.woocommerce-page {
  /* Remove strokes from woocommerce tables */
  .woocommerce table.shop_table {
    border: 0;

    td {
      border: 0;
    }
  }

  main.main {
    .woocommerce-container {
      margin-bottom: 60px;
    }

    .woocommerce-notices-wrapper {
      width: 100%;
    }

    .woocommerce-Message {
      .woocommerce-Button {
        margin-left: 15px;
      }
    }
  }

  .quantity > span {
    display: none;
  }

  /* Qty Input */
  .qinput {
    display: flex;
    height: 52px;

    input {
      appearance: none;
      height: 100%;
      margin: 0;
      border: 1px solid $primary;
      font-size: 1.25rem;
      line-height: 1;

      &:focus {
        outline: none;
      }
    }

    input[type='number'] {
      background: transparent;
      padding-left: 5px;
      padding-right: 5px;
    }

    .minus,
    .plus {
      cursor: pointer;
      width: 52px;
      padding: initial;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1;
      border-color: $primary;
      box-shadow: initial;
    }

    .minus {
      border-right: 0;
      @include border-right-radius(0);
    }

    .plus {
      border-left: 0;
      @include border-left-radius(0);
    }
  }

  /* Account */
  &.woocommerce-account {
    .woocommerce-MyAccount-navigation {
      ul {
        padding-left: 17px;
        margin: 0 0 30px 0;

        li {
          font-size: 0.875rem;
          margin-bottom: 10px;

          a {
            text-transform: uppercase;
          }
        }
      }
    }

    .woocommerce-MyAccount-content {
      form {
        label {
          font-weight: 800;
        }

        &.woocommerce-EditAccountForm {
          fieldset {
            margin-bottom: 30px;
            border: 1px solid #cccccc;
          }
        }
      }

      .woocommerce-Addresses {
        margin-top: 30px;

        @media only screen and (max-width: 768px) {
          .woocommerce-Address {
            margin-bottom: 30px;
          }
        }
      }

      .woocommerce-orders-table {
        font-size: 1rem;
      }
    }
  }

  /* Checkout */
  &.woocommerce-checkout {
    .col2-set {
      margin-bottom: 30px;
    }

    form.checkout {
      label {
        font-weight: 600;
      }

      #order_comments {
        height: 200px;
      }
    }

    .woocommerce-shipping-fields {
      margin: 15px 0;

      h3#ship-to-different-address {
        font-size: 1rem;
        text-transform: none;

        label,
        span {
          font-weight: 400;
        }
      }
    }

    #payment {
      background: transparent;

      ul.payment_methods {
        li {
          line-height: 1.6;
        }
      }

      .woocommerce-terms-and-conditions-wrapper {
        margin-bottom: 30px;
      }

      .button {
        color: $white;
        background: $secondary;

        &:hover {
          color: $secondary;
          background: $white;
        }
      }
    }
  }

  /* Cart */
  &.woocommerce-cart {
    .woocommerce-cart-form {
      .shop_table {
        tbody {
          tr {
            td {
              &.product-quantity {
                .quantity {
                  .qinput {
                    height: 35px;

                    @media only screen and (max-width: 768px) {
                      padding-left: 15px;
                    }

                    input[type='number'] {
                      width: 60px;
                      font-size: 1rem;
                      font-weight: 400;
                    }

                    .minus,
                    .plus {
                      cursor: pointer;
                      width: 35px;
                      font-size: 1rem;
                      font-weight: 600;
                    }
                  }
                }
              }

              @media only screen and (max-width: 768px) {
                &.actions {
                  button {
                    width: auto;
                  }
                }
              }
            }
          }
        }

        .coupon {
          height: 40px;

          #coupon_code {
            width: 150px;
            padding-left: 10px;
            padding-right: 10px;
            height: 100%;
            border-radius: 4px;
          }

          button {
            height: 100%;
          }
        }

        @media only screen and (max-width: 768px) {
          .coupon {
            height: auto;
            margin-bottom: 15px;
          }
        }
      }
    }

    .cart-collaterals {
      display: flex;
      justify-content: flex-end;

      .cart_totals {
        float: none;
        width: auto;

        tr {
          th {
            border: 0;
            padding-left: 0;
          }
        }

        .wc-proceed-to-checkout {
          .button {
            width: 100%;

            &.wc-forward {
              background: #16884F;
              border-color: #81D373;
              color: $white;

              &:hover {
                background: transparent;
                color: #16884F;
              }
            }
          }
        }
      }
    }

    .shop_table_responsive tr:nth-child(2n) td,
    .shop_table_responsive tr:nth-child(2n) td {
      background-color: $white;
    }
  }
}

.wp-block-cover {
  .wp-block-cover__inner-container {
    max-width: 882px;

    h1 {
      font-size: 32px;
      font-weight: 900;
      text-transform: uppercase;

      @include from('lg') {
        font-size: 48px;
      }
    }
  }
}

.wp-block-columns,
.wp-block-group,
.wp-block-cover {
  & + .wp-block-group,
  & + .wp-block-columns {
    margin-top: 48px;
    margin-bottom: 48px;

    @include from('lg') {
      margin-top: 96px;
      margin-bottom: 96px;
    }
  }
}

.wp-block-columns {
  @include from('lg') {
    &.container {
      padding: 0;
    }
  }

  .wp-block-column {
    & + .wp-block-column {
      margin-top: 48px;

      @include from('lg') {
        margin-top: 0;
      }
    }

    h2 {
      margin-bottom: 32px;
      font-weight: 900;
      text-transform: uppercase;
    }

    p {
      & + p {
        margin-top: 16px;
      }
    }
  }
}

.center-block {
  display: flex;
  justify-content: center;

  @include from('lg') {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  .wp-block-group__inner-container {
    max-width: 885px;
  }

  h2 {
    margin-bottom: 32px;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.wp-block-image {
  @include until('md') {
    .alignright {
      margin-left: 0;
    }
  }

  img {
    width: 100%;
    height: auto;
    min-width: 128px;
  }
}

.row-404 {
  position: relative;
  z-index: 10;

  &::after {
    content: '\f059';
    position: absolute;
    top: -80%;
    left: -10%;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 700;
    font-size: 250px;
    opacity: 0.05;
    z-index: -1;
  }
}

