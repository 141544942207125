.product-slider {
  & + .product-slider {
    margin-top: 48px;
  }

  .product-slider-meta {
    margin-bottom: 8px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba($primary, 0.4);

    h3 {
      font-size: 24px;
      font-weight: 900;
      text-transform: uppercase;

      @include from('lg') {
        font-size: 32px;
      }
    }

    .nav {
      display: flex;
      align-items: center;

      .swiper-button-prev,
      .swiper-button-next {
        position: initial;
        margin: 0;
        padding: 16px;
        border: 1px solid $primary;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba($black, 0.15);
        transition: all 0.2s ease;

        &::after {
          width: initial;
          height: initial;
          font-size: 14px;
        }

        &:hover {
          background: $primary;
          color: $white;
        }
      }

      .swiper-button-next {
        margin-left: 8px;
      }
    }
  }
}
