.top-nav {
  display: none;

  @include from('lg') {
    display: flex;
  }

  .container > .row > .col {
    border-bottom: 1px solid #d0d0d0;
  }

  .notice-section {
    font-size: 12px;
  }

  .contact-section {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;

    a {
      & + a {
        margin-left: 16px;
      }
    }
  }
}

.navigation {
  position: fixed;
  top: 0;
  padding: 16px 0;
  background: $white;
  z-index: $zIndex-9;

  @include from ('lg') {
    position: relative;
    padding: 32px 0;
  }

  .nav-toggle {
    @include from ('lg') {
      display: none;
    }
  }

  .menu-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include from('lg') {
      justify-content: flex-start;
    }

    .brand {
      @include until ('lg') {
        display: block;
        position: absolute;
        top: -16px;
        left: 50%;
        width: 100px;
        height: 100px;
        transform: translateX(-50%);
        max-width: 100px;
        border: 6px solid $white;
        border-radius: 50%;
      }
    }
  }


  ul {
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding: 10px 16px;

      &.menu-item-has-children {
        padding-right: 20px;

        &::after {
          content: url("../images/chevron-down.svg");
          position: absolute;
          display: inline-block;
          font-weight: normal;
          top: 30%;
          right: 0;

          @include until('lg') {
            position: absolute;
            top: 10px;
            right: 16px;
            cursor: pointer;
          }
        }
        @include until('lg') {
          &.active {
            .sub-menu {
              display: block;
            }
          }
        }

        @include from('lg') {
          &:hover {
            .sub-menu {
              display: block;
            }
          }
        }

        .sub-menu {
          display: none;
          position: absolute;
          min-width: 215px;
          background: $white;
          border: 1px solid #ddd;
          box-shadow: 0 4px 12px rgba($black, 0.2);
          z-index: $zIndex-9;

          @include until('lg') {
            padding: 12px;
            position: relative;
            width: 100%;
            height: auto;
            background: darken($white, 5%);
            box-shadow: none;
            transform: none;

            a {
              color: $black;
            }
          }

          li {
            a {
              display: block;
              font-weight: 500;
            }
          }
        }
      }

      &.cart-menu {
        padding-left: 25px;

        &::before {
          content: url("../images/shopping-cart.svg");
          position: absolute;
          display: inline-block;
          font-weight: normal;
          top: 30%;
          left: 0;
        }
      }

      &.user-menu {
        padding-left: 25px;

        &::before {
          content: url("../images/user.svg");
          position: absolute;
          display: inline-block;
          font-weight: normal;
          top: 30%;
          left: 0;
        }
      }

      a {
        color: $black;
        font-size: 18px;
        font-weight: 700;
        transition: all 0.2s ease;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }
    }

    @include from ('lg') {
      flex-direction: row;
    }

    @include until ('lg') {
      position: absolute;
      top: 8px;
      left: 0;
      width: 100vw;
      height: 100vh;
      max-width: 414px;
      padding: 32px 16px;
      background: $white;
      transform: translateX(-200%);
      transition: transform 0.2s ease;
      will-change: transform;
      overflow-y: scroll;
      z-index: $zIndex-8;

      &.active {
        transform: translateX(0);
      }

      ul {
        li {
          margin: 8px 0;

          a {
            color: $white;
            font-size: 18px;
          }
        }
      }
    }
  }

  .nav-primary {
    margin: 0 -16px;
  }

  .nav-search {
    @include until('lg') {
      position: relative;
      margin-top: -50%;
      transition: margin 0.2s ease;

      &.active {
        margin-top: 62px;
      }
    }
  }

  .search-toggle {
    display: none;

    @include until('lg') {
      display: block;
      position: relative;
      width: 20px;
      height: 20px;

      &::before {
        content: url("../images/search.svg");
        position: absolute;
      }
    }
  }
}
