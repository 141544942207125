.page-header {
  // overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-top: 32px;
  background-size: cover;
  background-position: center center;
  color: $white;

  @include from('lg') {
    margin-bottom: 64px;
    padding-top: initial;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.75);
    z-index: 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    height: 205px;
    z-index: $zIndex-1;
  }

  h1 {
    font-size: 28px;
    font-weight: 900;
    text-transform: uppercase;

    @include until('md') {
      text-align: center;
    }
  }

  .divider {
    width: 128px;
    height: 2px;
    margin: 20px 0;
    background: $white;
  }

  .crumbs {
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    a {
      color: $white;
      text-decoration: underline;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}
