*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  box-sizing: border-box;
  font: 100%/1.618 $ff-sans;
  font-feature-settings: "kern";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background: $white;
  color: $black;
  font-size: 18px;
  font-weight: 400;

  @include until('xl') {
    overflow-x: hidden;
  }
}

a {
  color: $primary;
  text-decoration: none;

  &:hover {
    color: lighten($primary, 10%);
    text-decoration: underline;
  }
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

.img-fluid {
  width: 100%;
  height: auto;
}
