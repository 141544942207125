.bottom-navigation {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: $white;
  box-shadow: 0 -2px 4px rgba($black, 0.1);
  z-index: $zIndex-5;

  @include from ('lg') {
    display: none;
  }

  ul {
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      width: 60px;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 10px 8px;
      }

      span {
        margin-top: 4px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
      }
    }
  }
}
